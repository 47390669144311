import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <main id="main" className="main main--page">
    <div className="main__container">
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </main>
)

export default NotFoundPage
